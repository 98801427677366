import React from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SEO from '../components/seo';
import IframeResizer from 'iframe-resizer-react';

const ContractsPage = () => {
  return (
    <Layout>
      <SEO title="Contracts" />
      <ContractsContainer>
        <h2 className="page-title">Contracts</h2>
        <IframeResizer
          src="https://police-contract-app.herokuapp.com/"
          frameBorder="0"
          style={{ width: '1px', minWidth: '100%' }}
        />
      </ContractsContainer>
    </Layout>
  );
};

export const ContractsContainer = styled.div`
  .page-title {
    text-align: center;
    margin-bottom: 1em;

    @media (max-width: 767.98px) {
      font-size: 30px;
      line-height: 1.375;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.5em;
  }

  .content {
    max-width: 1184px;
    margin: 0 auto;
    padding: 80px 104px;
    background: #fff;
    color: ${constants.contentTextColor};
    border-radius: 8px;

    @media (max-width: 767.98px) {
      padding: 40px 1rem;
      border-radius: 0;
    }

    .intro {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 2em;

      @media (max-width: 767.98px) {
        font-size: 18px;
        line-height: 1.375;
      }
    }

  .embed-responsive {
    box-sizing: border-box;
    display: block;
    line-height: 24px;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: left;
    width: 100%;
    margin-bottom: 1.5rem;

    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    iframe {
      border-width: 0;
      bottom: 0;
      box-sizing: border-box;
      height: 100%;
      left: 0;
      line-height: 24px;
      position: absolute;
      text-align: left;
      top: 0;
      width: 100%;
    }
  }
}`;

export default ContractsPage;